.mobileNavContainer {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index:10001;
	background-color: #ffe6d2;
	top: 56px;
	padding: 8px;
	color: #a56f43;
}
.fab {
	height: 56px;
	width: 56px;
	border-radius: 64px;
	position: fixed;
	bottom: 0;
	right:0;
	margin: 16px;
	background: #a56f43;
	color: white;
	border: none;
	z-index: 10002;
	  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.navbar ul {
	padding: 2px;
}
.navbar ul li {
	display: inline-block;
	background: white;
	padding: 4px;
	margin: 4px;
	border-radius: 4px;
	
}
.navbar ul li a{
	text-decoration: none;
	color: #a56f43;
}
.navbar ul li a:visited{
	text-decoration: none;
	color: #a56f43;;
}