.footer {
	background-color: #f8f9fa;
	padding: 1rem 0;
	margin-top: 2rem;
	text-align: center;
	font-size: 1.2rem;
	color: #6c757d;
}
.logo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
	margin: 8px;
	cursor: pointer;
}
.logo:hover {
	-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
	filter: grayscale(0%);
	transition: 0.2s all ease-in;
	cursor: pointer;	
}
.logoContainer {
	display: flex;
	justify-content: center;
	gap: 8px;
	margin-top: 8px;
}
@media only screen and (max-width: 600px) {
    .logoContainer {
		display: block
	}	
}


@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .logoContainer {
		display: block
	}	
}